<template>

  <div class="row">
    <div class="col-12">
      
      <table v-show="!loading" class="table table-borderless">
        <thead>
            <tr>
                <th scope="col"> {{ $t('events.filters.vehicle_registration').toUpperCase() }} </th>
                <th scope="col"> Message </th>
                <th scope="col"> Actions </th>
            </tr>
        </thead>
        <tbody style="vertical-align: middle;">
            <tr v-show="messages.length < 1">
                <td colspan="3" class="no-data-container"> {{ $t('global.no_data') }} </td>
            </tr>
            <!-- Table Content -->
            <tr v-for="elem in messages" :key="'message-' + elem.vehicle_id">
                <td>
                   <span class="vrm bold number-plate-gbr"> {{elem.vehicle.plate}} </span> 
                </td>
                <td>{{ elem.message }} </td>
                <td> 
                    <button :disabled="elem.removing" @click="deleteMessage(elem)" class="btn bg-danger" id="add-bl-btn"> 
                        <i class="material-icons"> close </i> 
                        {{ $t('global.remove') }}
                    </button> 
                </td>
            </tr>
        </tbody>
    </table>

    </div>
  </div>

</template>

<script>
import axios from "axios";
import authHeader from "@/services/auth-header";

export default {
  name: "CustomerService",
  data() {
    return {
      messages: [],
      loading: false,
      removingVehicle: false,
    };
  },
  mounted() {
    this.getMessages();
  },
  methods: {
    getMessages() {
      this.loading = true;
      axios
        .get("https://api.varsanpr.com/api/vehicle/customerservice", {
          headers: authHeader(),
          params: {
            client_id: this.$store.state.auth.user.selectedClient,
          }
        })
        .then((response) => {
          this.messages = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteMessage(message){
      message.removing = true;
      axios
        .delete("https://api.varsanpr.com/api/vehicle/customerservice", {
          headers: authHeader(),
          params: {
            client_id: this.$store.state.auth.user.selectedClient,
            message_id: message.id
          }
        })
        .then((response) => {
          this.messages = this.messages.filter((elem) => elem.id !== message.id);
          this.$success(`Successfully removed message for vehicle ${message.vehicle.plate}`);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.removingVehicle = false;
        });
    }
  },
}
</script>